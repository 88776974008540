import React, {useState,useEffect} from 'react'
import { useHistory } from 'react-router'
import Modal from 'react-modal'
import moment from 'moment';
// import close from '../assets/img/close.png'

function Home(props) {
    const history = useHistory();
    const { dataCurrentCp, dataCampaign, dataAPI } = props

    const [hasError, setHasError] = useState(false);
    const [hasErrorMsg, setHasErrorMsg] = useState('คุณยังไม่สามารถเข้า campaign นี้ได้')
    const [isLoaded, setIsLoaded] = useState(false);
    const [modalWinner, setModalWinner] = useState(false)
    const [modalTermCondition, setModalTermCondition] = useState(false)
    const [dataWinner, setdDataWinner] = useState([])

    // console.log(dataAPI)
    let windowH = window.innerHeight;

    useEffect(() => {
      setIsLoaded(true)
    }, [])
    
    const getWinnerList = () => {

      const fetchWinner =  async() => {
        const res = await fetch(`https://${dataAPI.apiLocation}/ld/api/user/winner_list/${dataAPI.cp_id}`, {
        method: 'GET',
        // body: JSON.stringify({
        //   "query": queryFinal
        // }),
        headers: {
          "Authorization": "Bearer "+ dataAPI.token,
          // "x-application-secret-key": dataAPI.apiXKey,
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      });

      res
        .json()
        .then(res => {
          if (res.success === 0 || res === null || res === undefined ) {
            setIsLoaded(true);
            setHasError(true);
          } else {
            setModalWinner(true)
            
            setIsLoaded(true);

            console.log(res)
            setdDataWinner(res.winner_list)
          }
        })
        .catch(err => {
          console.log(err);
          setHasError(true)
          setHasErrorMsg('ไม่สามารถเข้าระบบได้')
        });
      }
      
      fetchWinner()
    }

    const gotoPlaygame = () => {
        history.push('/playgame')
    }

    Modal.setAppElement('#root');

    if(!isLoaded) {
      return (
        <div className="App">
          <div className="App-loading">
            <p style={{color: '#000000', fontSize: '1.4rem'}}>...Loading...</p>
          </div>
        </div>
      );
  
     }  else {
      return (
          <div className="home-page" style={{backgroundImage: 'url('+dataCurrentCp.background_picture+')'}}>
            <br/>
            <br/>
            {
              dataCampaign.can_play ? 
              <div>
                <button className="btn-gotoplay" onClick={gotoPlaygame} style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                  {dataCurrentCp.button_action_first_page}
                </button>
              </div>
              :
              <div>
                <button className="btn-gotoplay" style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.already_played_text_color, fontSize: dataCurrentCp.already_played_text_size+'rem'}}>
                  { dataCurrentCp.status === 'end' ? 'พบกันใหม่เร็วๆ นี้' : dataCurrentCp.already_played_text }
                </button>
              </div>
            }

            <img src={dataCurrentCp.headline_picture} className="logo" alt="logo" />

            <div>
              <button className="App-link" onClick={()=>{setModalTermCondition(true)}} style={{color: '#'+dataCurrentCp.view_winner_text_color, fontSize: dataCurrentCp.view_winner_text_size+'rem', textDecoration: 'underline'}}>
                กติกาและเงื่อนไข
              </button>
            </div>
            <br/>
            <div>
              <button className="App-link" onClick={getWinnerList} style={{color: '#'+dataCurrentCp.view_winner_text_color, fontSize: dataCurrentCp.view_winner_text_size+'rem', textDecoration: 'underline'}}>
                รายชื่อผู้รับรางวัลทั้งหมด
              </button>
            </div>
              <Modal 
                  isOpen={modalTermCondition} 
                  className="_modal box-style modal-winner" 
                  style={{ 
                      overlay: {
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          zIndex: 3
                      }}
                  }
              >
                  <div className="_info-modal center" style={{ backgroundImage: 'url('+dataCurrentCp.prize_details_frame_picture+')'}}>
                                              
                      {/* <div className="btn-close" onClick={()=>setModalTermCondition(false)}>
                          <img src={close} alt="close" />
                      </div> */}
                      <h1>กติกาและเงื่อนไข</h1>
                      <div className="_body termcondition" style={{height: windowH - (windowH/2)+'px'}}>
                        {
                          <div dangerouslySetInnerHTML={{__html: dataCurrentCp.term_condition }}></div>
                        }
                      </div>
                      <button className="btn-gotoplay" onClick={()=>setModalTermCondition(false)} style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                        ย้อนกลับ
                      </button>
                  </div>
              </Modal>
  
              <Modal 
                  isOpen={modalWinner} 
                  className="_modal box-style modal-winner" 
                  style={{ 
                      overlay: {
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          zIndex: 3
                      }}
                  }
              >
                  <div className="_info-modal center" style={{ backgroundImage: 'url('+dataCurrentCp.prize_details_frame_picture+')'}}>
                                                                    
                      {/* <div className="btn-close" onClick={()=>setModalWinner(false)}>
                          <img src={close} alt="close" />
                      </div> */}
                      <h1>ดูรายชื่อผู้รับรางวัล</h1>
                      <div className="_body winnner" style={{height: windowH - (windowH/2)+'px'}}>
                        {
                          dataWinner.map((item,i)=>{
                            return(
                              <div key={i} className="modal-item">
                                <p className="number">หมายเลข {item.lucky_num} <span className="line"> | </span>{moment(item.ts).format('DD/MM/YY HH:mm:ss')}</p>
                                <p className="award"> รางวัล: {item.prize_name}</p>
                                <p className="name">{item.winner_name}</p>
                              </div>
                            )
                          })
                        }
                      </div>
                      <button className="btn-gotoplay" onClick={()=>setModalWinner(false)} style={{backgroundImage: 'url('+dataCurrentCp.button_picture+')', color: '#'+dataCurrentCp.button_action_text_color, fontSize: dataCurrentCp.button_action_text_size+'rem'}}>
                        ย้อนกลับ
                      </button>
                  </div>
              </Modal>
  
              <Modal 
                  isOpen={hasError} 
                  className="_modal box-style modal-winner" 
                  style={{ 
                      overlay: {
                          backgroundColor: 'rgba(0,0,0,0.5)',
                          zIndex: 3
                      }}
                  }
              >
                  <div className="_info-modal center" style={{ backgroundColor: '#ffffff'}}>
                      
                      <p>{hasErrorMsg}</p>
                      <br/>
                      <button className="btn-round _blue" onClick={()=>setHasError(false)}>
                        ตกลง
                      </button>
                  </div>
              </Modal>
          </div>
          
      )

     }

}

export default Home
